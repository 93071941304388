import { Box, makeStyles, Typography } from "@material-ui/core";
import { parseDate } from "chrono-node";
import clsx from "clsx";
import { format } from "date-fns";
import { useCallback, useMemo, VFC } from "react";
import { useForm } from "react-hook-form";
import { useMicroShortcuts } from "../../hooks/useMicroShortcuts";
import {
  DATE_DISPLAY_FORMAT,
  durationStrDecimal,
  getLargestContainedUnit,
  getLatestMinuteInterval,
  isDateToday,
  TIME_DISPLAY_FORMAT,
} from "../../utils/dates";
import { CircularProgressWithBackground } from "../CircularProgressWithBackground";
import { AsyncButton } from "./AsyncButton";
import { useMicroStyles } from "./micro";
import { RhfDateControl } from "./rhf/RhfDateControl";
import { DurationControl, parseDuration } from "./rhf/DurationControl";
import { RadioBox } from "./rhf/RadioBox";
import { RadioBoxGroupControl } from "./rhf/RadioBoxGroupControl";
import { RhfForm } from "./rhf/RhfForm";
import { parseTime, TimeControl, TimeControlValidators } from "./rhf/TimeControl";

const useStyles = makeStyles((theme) => ({
  logWorkWrapper: {
    alignItems: "flex-start",
    display: "flex",
    padding: 0,
  },
  dateControl: {
    width: theme.spacing(16),
  },
  timeControl: {
    width: theme.spacing(12),
  },
  spanLabel: {
    width: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(1, 3, 0, 0),
  },
  focusProgressLabel: {
    fontSize: 14,
    letterSpacing: "-.02em",
    lineHeight: 1,
    margin: 0,
    whiteSpace: "nowrap",
    textAlign: "center",
    fontWeight: "bold",
  },
  focusProgressSubLabel: {
    fontSize: 10,
    letterSpacing: 0,
    lineHeight: 1,
    margin: 0,
    whiteSpace: "nowrap",
    textAlign: "center",
    fontWeight: "normal",
    color: "rgba(0, 0, 0, 0.5)",
  },
  condFields: {
    marginLeft: theme.spacing(4),
  },
  durationField: {
    maxWidth: 100,
  },
}));

export type LogWorkPopperValues = {
  endTime: Date; // Represents the end time of the event as a date
  duration: number; // minutes
};

type FormValuesFieldToggle = "duration" | "startEnd";

type FormValues = {
  duration: string;
  date: string;
  start: string;
  end: string;
  fieldToggle: "duration" | "startEnd";
};

const fieldToggleKeys: { [K in FormValuesFieldToggle]: K } = {
  duration: "duration",
  startEnd: "startEnd",
};

const defaultValues = (): LogWorkPopperValues => ({
  endTime: new Date(),
  duration: 60,
});

const dataToFormValues = ({ endTime, duration }: LogWorkPopperValues): FormValues => {
  const lastInterval = getLatestMinuteInterval(endTime, 15);

  return {
    duration: "1hr",
    date: isDateToday(lastInterval) ? "" : format(lastInterval, DATE_DISPLAY_FORMAT),
    start: format(new Date(lastInterval.getTime() - duration * 60000), TIME_DISPLAY_FORMAT),
    end: format(lastInterval, TIME_DISPLAY_FORMAT),
    fieldToggle: "duration",
  };
};

const formValuesToData = (values: FormValues): LogWorkPopperValues => {
  const endTime = values.date === "" ? parseDate("today") : parseDate(values.date);

  const start = parseTime(values.start) as Date;
  const end = parseTime(values.end) as Date;
  const duration =
    values.fieldToggle === "startEnd"
      ? (end.getTime() - start.getTime()) / 1000 / 60
      : parseDuration(values.duration) || 0;

  // Set the date to the configured end time.
  endTime.setHours(end.getHours(), end.getMinutes(), 0, 0);

  return { endTime, duration };
};

export type LogWorkFormProps = {
  value?: LogWorkPopperValues;
  submitLabel?: string;
  secondsDone?: number;
  secondsTotal?: number;
  onCancel: () => void;
  onSubmit: (endTime: Date, duration: number) => void | Promise<void>;
};

export const LogWorkForm: VFC<LogWorkFormProps> = ({
  value,
  submitLabel = "Log work",
  onCancel,
  onSubmit,
  secondsDone,
  secondsTotal,
}) => {
  const classes = useStyles();
  const microClasses = useMicroStyles();

  const form = useForm<FormValues>({
    defaultValues: {
      ...(!!value ? dataToFormValues(value) : dataToFormValues(defaultValues())),
    },
    mode: "onBlur",
  });

  const watch = form.watch();

  const inputProps = useMemo(
    () => ({
      inputProps: {
        className: microClasses.inputRoot,
        classes: { input: microClasses.input },
      },
    }),
    [microClasses.inputRoot, microClasses.input]
  );

  const triggerEnd = useCallback(() => form.trigger("end"), [form]);
  const triggerStart = useCallback(() => form.trigger("start"), [form]);

  const hasError = useCallback(() => {
    return Object.keys(form.formState.errors).length !== 0;
  }, [form.formState]);

  const handleSubmit = useCallback(async () => {
    await form.trigger();

    if (!hasError()) {
      const { endTime, duration } = formValuesToData(form.getValues());
      await onSubmit(endTime, duration);
    }
  }, [form, hasError, onSubmit]);

  const validateEndTime = useCallback(
    (v: string) => {
      const endTime = formValuesToData({ ...form.getValues(), end: v }).endTime;
      const now = new Date();

      return endTime.getTime() > now.getTime() ? "Must be in the past" : true;
    },
    [form]
  );

  useMicroShortcuts(handleSubmit, onCancel);

  const hasProgress = typeof secondsDone === "number" && typeof secondsTotal === "number";

  const loggedLabel = useMemo(() => {
    if (!hasProgress) return "0";
    return durationStrDecimal(secondsDone, {
      fallbackUnit: getLargestContainedUnit(secondsTotal),
      noDaysOrWeeks: true,
    });
  }, [hasProgress, secondsDone, secondsTotal]);
  const progress = (secondsDone || 0) / (secondsTotal || 1);

  return (
    <Box className={classes.logWorkWrapper}>
      <CircularProgressWithBackground className={classes.progress} size={72} fraction={progress}>
        <Typography className={classes.focusProgressLabel} variant="h5">
          {loggedLabel.replace(" ", "")}
        </Typography>
        <Typography className={classes.focusProgressSubLabel} variant="h6">
          Done
        </Typography>
      </CircularProgressWithBackground>
      <RhfForm form={form} onSubmit={handleSubmit}>
        <RadioBoxGroupControl name="fieldToggle" orientation="vertical" defaultValue={fieldToggleKeys.duration}>
          <RadioBox styleless value={fieldToggleKeys.duration}>
            Duration
          </RadioBox>
          {watch.fieldToggle === "duration" && (
            <Box className={clsx(classes.condFields, microClasses.flexSection)}>
              <DurationControl
                className={classes.durationField}
                name="duration"
                aria-label="Duration"
                size="small"
                variant="outlined"
                rules={{
                  validate: {
                    min: (v) => {
                      const parsed = parseDuration(v);
                      return !!parsed && parsed < 15 ? "Minimum duration is 15 min" : true;
                    },
                  },
                }}
              />
            </Box>
          )}
          <RadioBox styleless value={fieldToggleKeys.startEnd}>
            Specific date & time
          </RadioBox>
          {watch.fieldToggle === "startEnd" && (
            <>
              <Box className={clsx(classes.condFields, microClasses.flexSection)}>
                <Typography className={microClasses.label}>Date</Typography>
                <RhfDateControl
                  name="date"
                  TextFieldClassName={clsx(classes.dateControl, microClasses.control)}
                  aria-label="Date"
                  size="small"
                  variant="outlined"
                  disableFuture={true}
                  disablePast={false}
                  dayMode
                  inlinePicker
                  InputProps={inputProps}
                  placeholder="Today"
                  onBlur={triggerEnd}
                />
              </Box>
              <Box className={clsx(classes.condFields, microClasses.flexSection)}>
                <Typography className={microClasses.label}>Time</Typography>
                <TimeControl
                  className={clsx(microClasses.control, classes.timeControl)}
                  name="start"
                  aria-label="Start time"
                  size="small"
                  variant="outlined"
                  InputProps={inputProps}
                  required="required"
                  rules={{
                    validate: {
                      beforeEnd: (v: string) =>
                        TimeControlValidators.beforeTime(v, form.getValues().end, "Must be less than end"),
                    },
                  }}
                />
                <Typography className={clsx(microClasses.label, classes.spanLabel)}>to</Typography>
                <TimeControl
                  className={clsx(microClasses.control, classes.timeControl)}
                  name="end"
                  aria-label="End time"
                  size="small"
                  variant="outlined"
                  required="required"
                  rules={{
                    validate: {
                      beforeNow: validateEndTime,
                    },
                  }}
                  onBlur={triggerStart}
                  InputProps={inputProps}
                />
              </Box>
            </>
          )}
        </RadioBoxGroupControl>
        <AsyncButton
          onClick={handleSubmit}
          className={microClasses.submitBtn}
          variant="contained"
          color="primary"
          size="small"
        >
          {submitLabel}
        </AsyncButton>
      </RhfForm>
    </Box>
  );
};
